var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[(
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeActivityTypes.viewData)
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'activityTypes' ? 'active' : '',attrs:{"to":{ name: 'ActivityTypes' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/activity-types.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("activityTypes.modelName")))])])],1):_vm._e(),(
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeBrands.viewData) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeBrandServices.viewData
      ) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeBrandPlaces.viewData) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeBrandUsers.viewData)
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theBrands",modifiers:{"theBrands":true}}],class:_vm.currentPage == 'theBrands' ? 'active' : ''},[_vm._m(0),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theBrands"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theBrands"}},[_c('ul',[(
            _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeBrands.viewData)
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'brands' ? 'active' : '',attrs:{"to":{ name: 'Brands' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/brand.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("brands.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeBrandServices.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'brandServices' ? 'active' : '',attrs:{"to":{ name: 'BrandServices' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/brand-services.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("brandServices.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeBrandPlaces.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'brandPlaces' ? 'active' : '',attrs:{"to":{ name: 'BrandPlaces' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/brand-places.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("brandPlaces.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeBrandUsers.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'brandUsers' ? 'active' : '',attrs:{"to":{ name: 'BrandUsers' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/brandUsers.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("BrandUsers.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(_vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSponsors.viewData))?_c('li',[_c('router-link',{class:_vm.currentPage == 'sponsors' ? 'active' : '',attrs:{"to":{ name: 'Sponsors' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/sponsors.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("sponsors.modelName")))])])],1):_vm._e(),(_vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeOffers.viewData))?_c('li',[_c('router-link',{class:_vm.currentPage == 'offers' ? 'active' : '',attrs:{"to":{ name: 'Offers' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/offers.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("offers")))])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeQrCodeSessions.enquiryQrCodeVCD
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeQrCodeSessions.generateMyQrCodeVCD
      )
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.QrCode",modifiers:{"QrCode":true}}],class:_vm.currentPage == 'QrCode' ? 'active' : ''},[_vm._m(1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("QR.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"QrCode"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeQrCodeSessions.enquiryQrCodeVCD
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'QrCodeSessionEnquiry' ? 'active' : '',attrs:{"to":{ name: 'QrCodeSessionEnquiry' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/qr-code-enquiry.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("QR.QREnquiry")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeQrCodeSessions
                .generateMyQrCodeVCD
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'QrCodeSessionGenerate' ? 'active' : '',attrs:{"to":{ name: 'QrCodeSessionGenerate' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/qr-code-generate.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("QR.QRGenerate")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeCardCreationPlans.viewData
      ) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeCards.viewData) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSellCards.viewData)
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.theCards",modifiers:{"theCards":true}}],class:_vm.currentPage == 'theCards' ? 'active' : ''},[_vm._m(2),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("theCards.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"theCards"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeCardCreationPlans.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'cardPlans' ? 'active' : '',attrs:{"to":{ name: 'CardPlans' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/cardPlans.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("CardPlans.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeCards.viewData)
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'cards' ? 'active' : '',attrs:{"to":{ name: 'Cards' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/cards.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Cards.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeSellCards.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'sellCards' ? 'active' : '',attrs:{"to":{ name: 'SellCards' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/sellCards.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("SellCards.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeMasterAdmins.viewData
      ) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeEmployees.viewData) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeClients.viewData) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSeller.viewData)
    )?_c('li',{staticClass:"sub-menu"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.users",modifiers:{"users":true}}],class:_vm.currentPage == 'users' ? 'active' : ''},[_vm._m(3),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("users.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])]),_c('b-collapse',{attrs:{"id":"users"}},[_c('ul',[(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeMasterAdmins.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'masterAdmins' ? 'active' : '',attrs:{"to":{ name: 'MasterAdmins' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/masterAdmins.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("masterAdmins.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(
              _vm.usersPrivilegeData.usersPrivilegeEmployees.viewData
            )
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'employees' ? 'active' : '',attrs:{"to":{ name: 'Employees' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/employees.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("employees.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeClients.viewData)
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'clients' ? 'active' : '',attrs:{"to":{ name: 'Clients' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/clients.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("clients.modelName")))])])],1):_vm._e(),(
            _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSeller.viewData)
          )?_c('li',[_c('router-link',{class:_vm.currentSubPage == 'sellers' ? 'active' : '',attrs:{"to":{ name: 'Sellers' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/sellers.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("sellers.modelName")))])])],1):_vm._e()])])],1):_vm._e(),(
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeBrands.report) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeCards.report) ||
      _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSellCards.report)
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'reports' ? 'active' : '',attrs:{"to":{ name: 'Reports' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/reports.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("reports")))])])],1):_vm._e(),(
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeEstablishmentRoles.viewData
      ) ||
      _vm.checkPrivilege(
        _vm.usersPrivilegeData.usersPrivilegeUserValidSetting.viewData
      )
    )?_c('li',[_c('router-link',{class:_vm.currentPage == 'settings' ? 'active' : '',attrs:{"to":{ name: 'Settings' }}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg")}})]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("settings")))])])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/brand.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/theCards.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/users.svg")}})])}]

export { render, staticRenderFns }