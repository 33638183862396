export default class UsersPrivilegeQrCodeSessions {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.generateMyQrCodeVCD = false;
    this.enquiryQrCodeVCD = false;
  }
  fillData(data) {
    if (data) {
      this.generateMyQrCodeVCD = data.generateMyQrCodeVCD;
      this.enquiryQrCodeVCD = data.enquiryQrCodeVCD;
    } else {
      this.setInitialValue();
    }
  }

  selectAll(status) {
    this.generateMyQrCodeVCD = status;
    this.enquiryQrCodeVCD = status;
  }
}
