import axios from "axios";
import EstablishmentRolesFilter from "./EstablishmentRolesFilter";
import GeneralPrivilege from "./GeneralPrivilege";
import GeneralPrivilegeWithReport from "./GeneralPrivilegeWithReport";
import UsersPrivilegeNotifications from "./UsersPrivilegeNotifications";
import UsersPrivilegeSellCards from "./UsersPrivilegeSellCards";
import UsersPrivilegeQrCodeSessions from "./UsersPrivilegeQrCodeSessions";
import EstablishmentRoleImg from "@/assets/images/establishment-role.svg";
import {
  PAGE_SIZE,
  STATUS,
  BASE_URL,
  ESTABLISHMENT_TOKEN,
} from "@/utils/constants";
import i18n from "@/i18n";
import store from "@/store";

export default class EstablishmentRole {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentRoleToken = "";
    this.dailyCode = "";
    this.fullCode = "";
    this.establishmentRoleNameCurrent = "";
    this.establishmentRoleNameEn = "";
    this.establishmentRoleNameAr = "";
    this.establishmentRoleNameUnd = "";
    this.establishmentRoleDescriptionCurrent = "";
    this.establishmentRoleDescriptionAr = "";
    this.establishmentRoleDescriptionEn = "";
    this.establishmentRoleDescriptionUnd = "";
    this.establishmentRoleImagePath = "";
    this.establishmentRoleNotes = "";
    this.userTypeToken = "";
    this.userTypeNameCurrent = "";
    this.userTypeNameAr = "";
    this.userTypeNameEn = "";
    this.userTypeNameUnd = "";
    this.establishmentRoleDefaultStatus = false;
    this.establishmentRoleArchiveStatus = false;
    this.establishmentToken = ESTABLISHMENT_TOKEN;
    this.usersPrivilegeEstablishmentRoles = new GeneralPrivilege();
    this.usersPrivilegeUserValidSetting = new GeneralPrivilege();
    this.usersPrivilegeClients = new GeneralPrivilege();
    this.usersPrivilegeEmployees = new GeneralPrivilege();
    this.usersPrivilegeMasterAdmins = new GeneralPrivilege();
    this.usersPrivilegeSeller = new GeneralPrivilege();
    this.usersPrivilegeActivityTypes = new GeneralPrivilege();
    this.usersPrivilegeCardCreationPlans = new GeneralPrivilege();
    this.usersPrivilegeCards = new GeneralPrivilegeWithReport();
    this.usersPrivilegeBrands = new GeneralPrivilegeWithReport();
    this.usersPrivilegeBrandUsers = new GeneralPrivilege();
    this.usersPrivilegeBrandPlaces = new GeneralPrivilege();
    this.usersPrivilegeBrandServices = new GeneralPrivilege();
    this.usersPrivilegeOffers = new GeneralPrivilege();
    this.usersPrivilegeSponsors = new GeneralPrivilege();
    this.usersPrivilegeQrCodeSessionsPurchases = new GeneralPrivilege();
    this.usersPrivilegeAdditionalSettings = new GeneralPrivilege();
    this.usersPrivilegeQrCodeSessions = new UsersPrivilegeQrCodeSessions();
    this.usersPrivilegeSellCards = new UsersPrivilegeSellCards();
    this.usersPrivilegeNotifications = new UsersPrivilegeNotifications();
    this.mediaFile = "";
    this.selectAllStatus = false;
  }
  fillData(data) {
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.dailyCode = data.dailyCode;
    this.fullCode = data.fullCode;
    this.establishmentRoleNameCurrent = data.establishmentRoleNameCurrent;
    this.establishmentRoleNameEn = data.establishmentRoleNameEn;
    this.establishmentRoleNameAr = data.establishmentRoleNameAr;
    this.establishmentRoleNameUnd = data.establishmentRoleNameUnd;
    this.establishmentRoleDescriptionCurrent =
      data.establishmentRoleDescriptionCurrent;
    this.establishmentRoleDescriptionAr = data.establishmentRoleDescriptionAr;
    this.establishmentRoleDescriptionEn = data.establishmentRoleDescriptionEn;
    this.establishmentRoleDescriptionUnd = data.establishmentRoleDescriptionUnd;
    this.establishmentRoleImagePath = data.establishmentRoleImagePath;
    this.establishmentRoleNotes = data.establishmentRoleNotes;
    this.userTypeToken = data.userTypeToken;
    this.userTypeNameCurrent = data.userTypeNameCurrent;
    this.userTypeNameAr = data.userTypeNameAr;
    this.userTypeNameEn = data.userTypeNameEn;
    this.userTypeNameUnd = data.userTypeNameUnd;
    this.establishmentRoleDefaultStatus = data.establishmentRoleDefaultStatus;
    this.establishmentRoleArchiveStatus = data.establishmentRoleArchiveStatus;
    this.establishmentToken = data.establishmentToken;
    this.usersPrivilegeEstablishmentRoles.fillData(
      data.usersPrivilegeEstablishmentRoles
    );
    this.usersPrivilegeUserValidSetting.fillData(
      data.usersPrivilegeUserValidSetting
    );
    this.usersPrivilegeClients.fillData(data.usersPrivilegeClients);
    this.usersPrivilegeEmployees.fillData(data.usersPrivilegeEmployees);
    this.usersPrivilegeMasterAdmins.fillData(data.usersPrivilegeMasterAdmins);
    this.usersPrivilegeSeller.fillData(data.usersPrivilegeSeller);
    this.usersPrivilegeActivityTypes.fillData(data.usersPrivilegeActivityTypes);
    this.usersPrivilegeCardCreationPlans.fillData(
      data.usersPrivilegeCardCreationPlans
    );
    this.usersPrivilegeCards.fillData(data.usersPrivilegeCards);
    this.usersPrivilegeBrands.fillData(data.usersPrivilegeBrands);
    this.usersPrivilegeBrandUsers.fillData(data.usersPrivilegeBrandUsers);
    this.usersPrivilegeBrandPlaces.fillData(data.usersPrivilegeBrandPlaces);
    this.usersPrivilegeBrandServices.fillData(data.usersPrivilegeBrandServices);
    this.usersPrivilegeOffers.fillData(data.usersPrivilegeOffers);
    this.usersPrivilegeSponsors.fillData(data.usersPrivilegeSponsors);
    this.usersPrivilegeQrCodeSessions.fillData(
      data.usersPrivilegeQrCodeSessions
    );
    this.usersPrivilegeSellCards.fillData(data.usersPrivilegeSellCards);
    this.usersPrivilegeQrCodeSessionsPurchases.fillData(
      data.usersPrivilegeQrCodeSessionsPurchases
    );
    this.usersPrivilegeNotifications.fillData(data.usersPrivilegeNotifications);
    this.usersPrivilegeAdditionalSettings.fillData(
      data.usersPrivilegeAdditionalSettings
    );
  }

  selectAll() {
    let status = !this.selectAllStatus;
    this.selectAllStatus = !this.selectAllStatus;
    this.usersPrivilegeEstablishmentRoles.selectAll(status);
    this.usersPrivilegeUserValidSetting.selectAll(status);
    this.usersPrivilegeClients.selectAll(status);
    this.usersPrivilegeEmployees.selectAll(status);
    this.usersPrivilegeMasterAdmins.selectAll(status);
    this.usersPrivilegeSeller.selectAll(status);
    this.usersPrivilegeActivityTypes.selectAll(status);
    this.usersPrivilegeCardCreationPlans.selectAll(status);
    this.usersPrivilegeCards.selectAll(status);
    this.usersPrivilegeBrands.selectAll(status);
    this.usersPrivilegeBrandUsers.selectAll(status);
    this.usersPrivilegeBrandPlaces.selectAll(status);
    this.usersPrivilegeBrandServices.selectAll(status);
    this.usersPrivilegeOffers.selectAll(status);
    this.usersPrivilegeSponsors.selectAll(status);
    this.usersPrivilegeQrCodeSessionsPurchases.selectAll(status);
    this.usersPrivilegeAdditionalSettings.selectAll(status);
    this.usersPrivilegeQrCodeSessions.selectAll(status);
    this.usersPrivilegeSellCards.selectAll(status);
    this.usersPrivilegeNotifications.selectAll(status);
  }

  async getAllEstablishmentRoles(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/EstablishmentRoles/GetAllEstablishmentRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&userTypeToken=${filterData.userTypeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getDialogOfEstablishmentRoles(
    language,
    userAuthorizeToken,
    filterData = new EstablishmentRolesFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/EstablishmentRoles/GetEstablishmentRoleDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&userTypeToken=${filterData.userTypeToken}&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : EstablishmentRoleImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: EstablishmentRoleImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: EstablishmentRoleImg,
      });
    }
    return options;
  }

  async getEstablishmentRoleDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/EstablishmentRoles/GetEstablishmentRoleDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.establishmentRoleToken}`
    );
  }

  async addOrUpdateEstablishmentRole(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("establishmentRoleToken", this.establishmentRoleToken);
    formData.append("fullCode", this.fullCode);
    formData.append("userTypeToken", this.userTypeToken);
    formData.append("establishmentRoleNameEn", this.establishmentRoleNameEn);
    formData.append("establishmentRoleNameAr", this.establishmentRoleNameAr);
    formData.append("establishmentRoleNameUnd", this.establishmentRoleNameUnd);
    formData.append(
      "establishmentRoleDescriptionEn",
      this.establishmentRoleDescriptionEn
    );
    formData.append(
      "establishmentRoleDescriptionAr",
      this.establishmentRoleDescriptionAr
    );
    formData.append(
      "establishmentRoleDescriptionUnd",
      this.establishmentRoleDescriptionUnd
    );
    formData.append("establishmentRoleNotes", this.establishmentRoleNotes);
    formData.append(
      "establishmentRoleDefaultStatus",
      this.establishmentRoleDefaultStatus
    );

    //#region usersPrivilegeEstablishmentRoles
    formData.append(
      "usersPrivilegeEstablishmentRoles.view",
      this.usersPrivilegeEstablishmentRoles.view
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.viewSelfData",
      this.usersPrivilegeEstablishmentRoles.viewSelfData
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.viewData",
      this.usersPrivilegeEstablishmentRoles.viewData
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.viewDialog",
      this.usersPrivilegeEstablishmentRoles.viewDialog
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.add",
      this.usersPrivilegeEstablishmentRoles.add
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.update",
      this.usersPrivilegeEstablishmentRoles.update
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.archive",
      this.usersPrivilegeEstablishmentRoles.archive
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.restore",
      this.usersPrivilegeEstablishmentRoles.restore
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.deleteImage",
      this.usersPrivilegeEstablishmentRoles.deleteImage
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.delete",
      this.usersPrivilegeEstablishmentRoles.delete
    );
    formData.append(
      "usersPrivilegeEstablishmentRoles.notification",
      this.usersPrivilegeEstablishmentRoles.notification
    );
    //#endregion usersPrivilegeEstablishmentRoles

    //#region usersPrivilegeUserValidSetting
    formData.append(
      "usersPrivilegeUserValidSetting.view",
      this.usersPrivilegeUserValidSetting.view
    );
    formData.append(
      "usersPrivilegeUserValidSetting.viewSelfData",
      this.usersPrivilegeUserValidSetting.viewSelfData
    );
    formData.append(
      "usersPrivilegeUserValidSetting.viewData",
      this.usersPrivilegeUserValidSetting.viewData
    );
    formData.append(
      "usersPrivilegeUserValidSetting.viewDialog",
      this.usersPrivilegeUserValidSetting.viewDialog
    );
    formData.append(
      "usersPrivilegeUserValidSetting.add",
      this.usersPrivilegeUserValidSetting.add
    );
    formData.append(
      "usersPrivilegeUserValidSetting.update",
      this.usersPrivilegeUserValidSetting.update
    );
    formData.append(
      "usersPrivilegeUserValidSetting.archive",
      this.usersPrivilegeUserValidSetting.archive
    );
    formData.append(
      "usersPrivilegeUserValidSetting.restore",
      this.usersPrivilegeUserValidSetting.restore
    );
    formData.append(
      "usersPrivilegeUserValidSetting.deleteImage",
      this.usersPrivilegeUserValidSetting.deleteImage
    );
    formData.append(
      "usersPrivilegeUserValidSetting.delete",
      this.usersPrivilegeUserValidSetting.delete
    );
    formData.append(
      "usersPrivilegeUserValidSetting.notification",
      this.usersPrivilegeUserValidSetting.notification
    );
    //#endregion usersPrivilegeUserValidSetting

    //#region usersPrivilegeClients
    formData.append(
      "usersPrivilegeClients.view",
      this.usersPrivilegeClients.view
    );
    formData.append(
      "usersPrivilegeClients.viewSelfData",
      this.usersPrivilegeClients.viewSelfData
    );
    formData.append(
      "usersPrivilegeClients.viewData",
      this.usersPrivilegeClients.viewData
    );
    formData.append(
      "usersPrivilegeClients.viewDialog",
      this.usersPrivilegeClients.viewDialog
    );
    formData.append(
      "usersPrivilegeClients.add",
      this.usersPrivilegeClients.add
    );
    formData.append(
      "usersPrivilegeClients.update",
      this.usersPrivilegeClients.update
    );
    formData.append(
      "usersPrivilegeClients.archive",
      this.usersPrivilegeClients.archive
    );
    formData.append(
      "usersPrivilegeClients.restore",
      this.usersPrivilegeClients.restore
    );
    formData.append(
      "usersPrivilegeClients.deleteImage",
      this.usersPrivilegeClients.deleteImage
    );
    formData.append(
      "usersPrivilegeClients.delete",
      this.usersPrivilegeClients.delete
    );
    formData.append(
      "usersPrivilegeClients.notification",
      this.usersPrivilegeClients.notification
    );
    //#endregion usersPrivilegeClients

    //#region usersPrivilegeEmployees
    formData.append(
      "usersPrivilegeEmployees.view",
      this.usersPrivilegeEmployees.view
    );
    formData.append(
      "usersPrivilegeEmployees.viewSelfData",
      this.usersPrivilegeEmployees.viewSelfData
    );
    formData.append(
      "usersPrivilegeEmployees.viewData",
      this.usersPrivilegeEmployees.viewData
    );
    formData.append(
      "usersPrivilegeEmployees.viewDialog",
      this.usersPrivilegeEmployees.viewDialog
    );
    formData.append(
      "usersPrivilegeEmployees.add",
      this.usersPrivilegeEmployees.add
    );
    formData.append(
      "usersPrivilegeEmployees.update",
      this.usersPrivilegeEmployees.update
    );
    formData.append(
      "usersPrivilegeEmployees.archive",
      this.usersPrivilegeEmployees.archive
    );
    formData.append(
      "usersPrivilegeEmployees.restore",
      this.usersPrivilegeEmployees.restore
    );
    formData.append(
      "usersPrivilegeEmployees.deleteImage",
      this.usersPrivilegeEmployees.deleteImage
    );
    formData.append(
      "usersPrivilegeEmployees.delete",
      this.usersPrivilegeEmployees.delete
    );
    formData.append(
      "usersPrivilegeEmployees.notification",
      this.usersPrivilegeEmployees.notification
    );
    //#endregion usersPrivilegeEmployees

    //#region usersPrivilegeMasterAdmins
    formData.append(
      "usersPrivilegeMasterAdmins.view",
      this.usersPrivilegeMasterAdmins.view
    );
    formData.append(
      "usersPrivilegeMasterAdmins.viewSelfData",
      this.usersPrivilegeMasterAdmins.viewSelfData
    );
    formData.append(
      "usersPrivilegeMasterAdmins.viewData",
      this.usersPrivilegeMasterAdmins.viewData
    );
    formData.append(
      "usersPrivilegeMasterAdmins.viewDialog",
      this.usersPrivilegeMasterAdmins.viewDialog
    );
    formData.append(
      "usersPrivilegeMasterAdmins.add",
      this.usersPrivilegeMasterAdmins.add
    );
    formData.append(
      "usersPrivilegeMasterAdmins.update",
      this.usersPrivilegeMasterAdmins.update
    );
    formData.append(
      "usersPrivilegeMasterAdmins.archive",
      this.usersPrivilegeMasterAdmins.archive
    );
    formData.append(
      "usersPrivilegeMasterAdmins.restore",
      this.usersPrivilegeMasterAdmins.restore
    );
    formData.append(
      "usersPrivilegeMasterAdmins.deleteImage",
      this.usersPrivilegeMasterAdmins.deleteImage
    );
    formData.append(
      "usersPrivilegeMasterAdmins.delete",
      this.usersPrivilegeMasterAdmins.delete
    );
    formData.append(
      "usersPrivilegeMasterAdmins.notification",
      this.usersPrivilegeMasterAdmins.notification
    );
    //#endregion usersPrivilegeMasterAdmins

    //#region usersPrivilegeSeller
    formData.append(
      "usersPrivilegeSeller.view",
      this.usersPrivilegeSeller.view
    );
    formData.append(
      "usersPrivilegeSeller.viewSelfData",
      this.usersPrivilegeSeller.viewSelfData
    );
    formData.append(
      "usersPrivilegeSeller.viewData",
      this.usersPrivilegeSeller.viewData
    );
    formData.append(
      "usersPrivilegeSeller.viewDialog",
      this.usersPrivilegeSeller.viewDialog
    );
    formData.append("usersPrivilegeSeller.add", this.usersPrivilegeSeller.add);
    formData.append(
      "usersPrivilegeSeller.update",
      this.usersPrivilegeSeller.update
    );
    formData.append(
      "usersPrivilegeSeller.archive",
      this.usersPrivilegeSeller.archive
    );
    formData.append(
      "usersPrivilegeSeller.restore",
      this.usersPrivilegeSeller.restore
    );
    formData.append(
      "usersPrivilegeSeller.deleteImage",
      this.usersPrivilegeSeller.deleteImage
    );
    formData.append(
      "usersPrivilegeSeller.delete",
      this.usersPrivilegeSeller.delete
    );
    formData.append(
      "usersPrivilegeSeller.notification",
      this.usersPrivilegeSeller.notification
    );
    //#endregion usersPrivilegeSeller

    //#region usersPrivilegeActivityTypes
    formData.append(
      "usersPrivilegeActivityTypes.view",
      this.usersPrivilegeActivityTypes.view
    );
    formData.append(
      "usersPrivilegeActivityTypes.viewSelfData",
      this.usersPrivilegeActivityTypes.viewSelfData
    );
    formData.append(
      "usersPrivilegeActivityTypes.viewData",
      this.usersPrivilegeActivityTypes.viewData
    );
    formData.append(
      "usersPrivilegeActivityTypes.viewDialog",
      this.usersPrivilegeActivityTypes.viewDialog
    );
    formData.append(
      "usersPrivilegeActivityTypes.add",
      this.usersPrivilegeActivityTypes.add
    );
    formData.append(
      "usersPrivilegeActivityTypes.update",
      this.usersPrivilegeActivityTypes.update
    );
    formData.append(
      "usersPrivilegeActivityTypes.archive",
      this.usersPrivilegeActivityTypes.archive
    );
    formData.append(
      "usersPrivilegeActivityTypes.restore",
      this.usersPrivilegeActivityTypes.restore
    );
    formData.append(
      "usersPrivilegeActivityTypes.deleteImage",
      this.usersPrivilegeActivityTypes.deleteImage
    );
    formData.append(
      "usersPrivilegeActivityTypes.delete",
      this.usersPrivilegeActivityTypes.delete
    );
    formData.append(
      "usersPrivilegeActivityTypes.notification",
      this.usersPrivilegeActivityTypes.notification
    );
    //#endregion usersPrivilegeActivityTypes

    //#region usersPrivilegeCardCreationPlans
    formData.append(
      "usersPrivilegeCardCreationPlans.view",
      this.usersPrivilegeCardCreationPlans.view
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.viewSelfData",
      this.usersPrivilegeCardCreationPlans.viewSelfData
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.viewData",
      this.usersPrivilegeCardCreationPlans.viewData
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.viewDialog",
      this.usersPrivilegeCardCreationPlans.viewDialog
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.add",
      this.usersPrivilegeCardCreationPlans.add
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.update",
      this.usersPrivilegeCardCreationPlans.update
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.archive",
      this.usersPrivilegeCardCreationPlans.archive
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.restore",
      this.usersPrivilegeCardCreationPlans.restore
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.deleteImage",
      this.usersPrivilegeCardCreationPlans.deleteImage
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.delete",
      this.usersPrivilegeCardCreationPlans.delete
    );
    formData.append(
      "usersPrivilegeCardCreationPlans.notification",
      this.usersPrivilegeCardCreationPlans.notification
    );
    //#endregion usersPrivilegeCardCreationPlans

    //#region usersPrivilegeCards
    formData.append("usersPrivilegeCards.view", this.usersPrivilegeCards.view);
    formData.append(
      "usersPrivilegeCards.viewSelfData",
      this.usersPrivilegeCards.viewSelfData
    );
    formData.append(
      "usersPrivilegeCards.viewData",
      this.usersPrivilegeCards.viewData
    );
    formData.append(
      "usersPrivilegeCards.viewDialog",
      this.usersPrivilegeCards.viewDialog
    );
    formData.append("usersPrivilegeCards.add", this.usersPrivilegeCards.add);
    formData.append(
      "usersPrivilegeCards.update",
      this.usersPrivilegeCards.update
    );
    formData.append(
      "usersPrivilegeCards.archive",
      this.usersPrivilegeCards.archive
    );
    formData.append(
      "usersPrivilegeCards.restore",
      this.usersPrivilegeCards.restore
    );
    formData.append(
      "usersPrivilegeCards.deleteImage",
      this.usersPrivilegeCards.deleteImage
    );
    formData.append(
      "usersPrivilegeCards.delete",
      this.usersPrivilegeCards.delete
    );
    formData.append(
      "usersPrivilegeCards.notification",
      this.usersPrivilegeCards.notification
    );
    formData.append(
      "usersPrivilegeCards.report",
      this.usersPrivilegeCards.report
    );
    //#endregion usersPrivilegeCards

    //#region usersPrivilegeBrands
    formData.append(
      "usersPrivilegeBrands.view",
      this.usersPrivilegeBrands.view
    );
    formData.append(
      "usersPrivilegeBrands.viewSelfData",
      this.usersPrivilegeBrands.viewSelfData
    );
    formData.append(
      "usersPrivilegeBrands.viewData",
      this.usersPrivilegeBrands.viewData
    );
    formData.append(
      "usersPrivilegeBrands.viewDialog",
      this.usersPrivilegeBrands.viewDialog
    );
    formData.append("usersPrivilegeBrands.add", this.usersPrivilegeBrands.add);
    formData.append(
      "usersPrivilegeBrands.update",
      this.usersPrivilegeBrands.update
    );
    formData.append(
      "usersPrivilegeBrands.archive",
      this.usersPrivilegeBrands.archive
    );
    formData.append(
      "usersPrivilegeBrands.restore",
      this.usersPrivilegeBrands.restore
    );
    formData.append(
      "usersPrivilegeBrands.deleteImage",
      this.usersPrivilegeBrands.deleteImage
    );
    formData.append(
      "usersPrivilegeBrands.delete",
      this.usersPrivilegeBrands.delete
    );
    formData.append(
      "usersPrivilegeBrands.notification",
      this.usersPrivilegeBrands.notification
    );
    formData.append(
      "usersPrivilegeBrands.report",
      this.usersPrivilegeBrands.report
    );
    //#endregion usersPrivilegeBrands

    //#region usersPrivilegeBrandUsers
    formData.append(
      "usersPrivilegeBrandUsers.view",
      this.usersPrivilegeBrandUsers.view
    );
    formData.append(
      "usersPrivilegeBrandUsers.viewSelfData",
      this.usersPrivilegeBrandUsers.viewSelfData
    );
    formData.append(
      "usersPrivilegeBrandUsers.viewData",
      this.usersPrivilegeBrandUsers.viewData
    );
    formData.append(
      "usersPrivilegeBrandUsers.viewDialog",
      this.usersPrivilegeBrandUsers.viewDialog
    );
    formData.append(
      "usersPrivilegeBrandUsers.add",
      this.usersPrivilegeBrandUsers.add
    );
    formData.append(
      "usersPrivilegeBrandUsers.update",
      this.usersPrivilegeBrandUsers.update
    );
    formData.append(
      "usersPrivilegeBrandUsers.archive",
      this.usersPrivilegeBrandUsers.archive
    );
    formData.append(
      "usersPrivilegeBrandUsers.restore",
      this.usersPrivilegeBrandUsers.restore
    );
    formData.append(
      "usersPrivilegeBrandUsers.deleteImage",
      this.usersPrivilegeBrandUsers.deleteImage
    );
    formData.append(
      "usersPrivilegeBrandUsers.delete",
      this.usersPrivilegeBrandUsers.delete
    );
    formData.append(
      "usersPrivilegeBrandUsers.notification",
      this.usersPrivilegeBrandUsers.notification
    );
    //#endregion usersPrivilegeBrandUsers

    //#region usersPrivilegeBrandPlaces
    formData.append(
      "usersPrivilegeBrandPlaces.view",
      this.usersPrivilegeBrandPlaces.view
    );
    formData.append(
      "usersPrivilegeBrandPlaces.viewSelfData",
      this.usersPrivilegeBrandPlaces.viewSelfData
    );
    formData.append(
      "usersPrivilegeBrandPlaces.viewData",
      this.usersPrivilegeBrandPlaces.viewData
    );
    formData.append(
      "usersPrivilegeBrandPlaces.viewDialog",
      this.usersPrivilegeBrandPlaces.viewDialog
    );
    formData.append(
      "usersPrivilegeBrandPlaces.add",
      this.usersPrivilegeBrandPlaces.add
    );
    formData.append(
      "usersPrivilegeBrandPlaces.update",
      this.usersPrivilegeBrandPlaces.update
    );
    formData.append(
      "usersPrivilegeBrandPlaces.archive",
      this.usersPrivilegeBrandPlaces.archive
    );
    formData.append(
      "usersPrivilegeBrandPlaces.restore",
      this.usersPrivilegeBrandPlaces.restore
    );
    formData.append(
      "usersPrivilegeBrandPlaces.deleteImage",
      this.usersPrivilegeBrandPlaces.deleteImage
    );
    formData.append(
      "usersPrivilegeBrandPlaces.delete",
      this.usersPrivilegeBrandPlaces.delete
    );
    formData.append(
      "usersPrivilegeBrandPlaces.notification",
      this.usersPrivilegeBrandPlaces.notification
    );
    //#endregion usersPrivilegeBrandPlaces

    //#region usersPrivilegeBrandServices
    formData.append(
      "usersPrivilegeBrandServices.view",
      this.usersPrivilegeBrandServices.view
    );
    formData.append(
      "usersPrivilegeBrandServices.viewSelfData",
      this.usersPrivilegeBrandServices.viewSelfData
    );
    formData.append(
      "usersPrivilegeBrandServices.viewData",
      this.usersPrivilegeBrandServices.viewData
    );
    formData.append(
      "usersPrivilegeBrandServices.viewDialog",
      this.usersPrivilegeBrandServices.viewDialog
    );
    formData.append(
      "usersPrivilegeBrandServices.add",
      this.usersPrivilegeBrandServices.add
    );
    formData.append(
      "usersPrivilegeBrandServices.update",
      this.usersPrivilegeBrandServices.update
    );
    formData.append(
      "usersPrivilegeBrandServices.archive",
      this.usersPrivilegeBrandServices.archive
    );
    formData.append(
      "usersPrivilegeBrandServices.restore",
      this.usersPrivilegeBrandServices.restore
    );
    formData.append(
      "usersPrivilegeBrandServices.deleteImage",
      this.usersPrivilegeBrandServices.deleteImage
    );
    formData.append(
      "usersPrivilegeBrandServices.delete",
      this.usersPrivilegeBrandServices.delete
    );
    formData.append(
      "usersPrivilegeBrandServices.notification",
      this.usersPrivilegeBrandServices.notification
    );
    //#endregion usersPrivilegeBrandServices

    //#region usersPrivilegeOffers
    formData.append(
      "usersPrivilegeOffers.view",
      this.usersPrivilegeOffers.view
    );
    formData.append(
      "usersPrivilegeOffers.viewSelfData",
      this.usersPrivilegeOffers.viewSelfData
    );
    formData.append(
      "usersPrivilegeOffers.viewData",
      this.usersPrivilegeOffers.viewData
    );
    formData.append(
      "usersPrivilegeOffers.viewDialog",
      this.usersPrivilegeOffers.viewDialog
    );
    formData.append("usersPrivilegeOffers.add", this.usersPrivilegeOffers.add);
    formData.append(
      "usersPrivilegeOffers.update",
      this.usersPrivilegeOffers.update
    );
    formData.append(
      "usersPrivilegeOffers.archive",
      this.usersPrivilegeOffers.archive
    );
    formData.append(
      "usersPrivilegeOffers.restore",
      this.usersPrivilegeOffers.restore
    );
    formData.append(
      "usersPrivilegeOffers.deleteImage",
      this.usersPrivilegeOffers.deleteImage
    );
    formData.append(
      "usersPrivilegeOffers.delete",
      this.usersPrivilegeOffers.delete
    );
    formData.append(
      "usersPrivilegeOffers.notification",
      this.usersPrivilegeOffers.notification
    );
    //#endregion usersPrivilegeOffers

    //#region usersPrivilegeSponsors
    formData.append(
      "usersPrivilegeSponsors.view",
      this.usersPrivilegeSponsors.view
    );
    formData.append(
      "usersPrivilegeSponsors.viewSelfData",
      this.usersPrivilegeSponsors.viewSelfData
    );
    formData.append(
      "usersPrivilegeSponsors.viewData",
      this.usersPrivilegeSponsors.viewData
    );
    formData.append(
      "usersPrivilegeSponsors.viewDialog",
      this.usersPrivilegeSponsors.viewDialog
    );
    formData.append(
      "usersPrivilegeSponsors.add",
      this.usersPrivilegeSponsors.add
    );
    formData.append(
      "usersPrivilegeSponsors.update",
      this.usersPrivilegeSponsors.update
    );
    formData.append(
      "usersPrivilegeSponsors.archive",
      this.usersPrivilegeSponsors.archive
    );
    formData.append(
      "usersPrivilegeSponsors.restore",
      this.usersPrivilegeSponsors.restore
    );
    formData.append(
      "usersPrivilegeSponsors.deleteImage",
      this.usersPrivilegeSponsors.deleteImage
    );
    formData.append(
      "usersPrivilegeSponsors.delete",
      this.usersPrivilegeSponsors.delete
    );
    formData.append(
      "usersPrivilegeSponsors.notification",
      this.usersPrivilegeSponsors.notification
    );
    //#endregion usersPrivilegeSponsors

    //#region usersPrivilegeQrCodeSessionsPurchases
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.view",
      this.usersPrivilegeQrCodeSessionsPurchases.view
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.viewSelfData",
      this.usersPrivilegeQrCodeSessionsPurchases.viewSelfData
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.viewData",
      this.usersPrivilegeQrCodeSessionsPurchases.viewData
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.viewDialog",
      this.usersPrivilegeQrCodeSessionsPurchases.viewDialog
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.add",
      this.usersPrivilegeQrCodeSessionsPurchases.add
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.update",
      this.usersPrivilegeQrCodeSessionsPurchases.update
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.archive",
      this.usersPrivilegeQrCodeSessionsPurchases.archive
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.restore",
      this.usersPrivilegeQrCodeSessionsPurchases.restore
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.deleteImage",
      this.usersPrivilegeQrCodeSessionsPurchases.deleteImage
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.delete",
      this.usersPrivilegeQrCodeSessionsPurchases.delete
    );
    formData.append(
      "usersPrivilegeQrCodeSessionsPurchases.notification",
      this.usersPrivilegeQrCodeSessionsPurchases.notification
    );
    //#endregion usersPrivilegeQrCodeSessionsPurchases

    //#region usersPrivilegeNotifications
    formData.append(
      "usersPrivilegeNotifications.notificationView",
      this.usersPrivilegeNotifications.notificationView
    );
    formData.append(
      "usersPrivilegeNotifications.notificationRead",
      this.usersPrivilegeNotifications.notificationRead
    );
    formData.append(
      "usersPrivilegeNotifications.notificationOpen",
      this.usersPrivilegeNotifications.notificationOpen
    );
    formData.append(
      "usersPrivilegeNotifications.notificationArchive",
      this.usersPrivilegeNotifications.notificationArchive
    );
    formData.append(
      "usersPrivilegeNotifications.notificationAdd",
      this.usersPrivilegeNotifications.notificationAdd
    );
    formData.append(
      "usersPrivilegeNotifications.notificationAddGenral",
      this.usersPrivilegeNotifications.notificationAddGenral
    );
    //#endregion usersPrivilegeNotifications

    //#region usersPrivilegeSellCards
    formData.append(
      "usersPrivilegeSellCards.view",
      this.usersPrivilegeSellCards.view
    );
    formData.append(
      "usersPrivilegeSellCards.viewData",
      this.usersPrivilegeSellCards.viewData
    );
    formData.append(
      "usersPrivilegeSellCards.viewDialog",
      this.usersPrivilegeSellCards.viewDialog
    );
    formData.append(
      "usersPrivilegeSellCards.sellCards",
      this.usersPrivilegeSellCards.sellCards
    );
    formData.append(
      "usersPrivilegeSellCards.notification",
      this.usersPrivilegeSellCards.notification
    );
    formData.append(
      "usersPrivilegeSellCards.report",
      this.usersPrivilegeSellCards.report
    );
    //#endregion usersPrivilegeSellCards

    //#region usersPrivilegeQrCodeSessions
    formData.append(
      "usersPrivilegeQrCodeSessions.generateMyQrCodeVCD",
      this.usersPrivilegeQrCodeSessions.generateMyQrCodeVCD
    );
    formData.append(
      "usersPrivilegeQrCodeSessions.enquiryQrCodeVCD",
      this.usersPrivilegeQrCodeSessions.enquiryQrCodeVCD
    );
    //#endregion usersPrivilegeQrCodeSessions

    if (
      this.establishmentRoleToken == "" ||
      this.establishmentRoleToken == undefined
    ) {
      return await axios.post(
        `/api/EstablishmentRoles/AddEstablishmentRole`,
        formData
      );
    } else {
      return await axios.post(
        `/api/EstablishmentRoles/UpdateEstablishmentRole`,
        formData
      );
    }
  }

  async archiveEstablishmentRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.establishmentRoleToken,
      archiveStatus: true,
    };

    return await axios.post(
      `/api/EstablishmentRoles/ArchiveEstablishmentRole`,
      data
    );
  }
}
